<template>
    <div>
        <!-- <div class="relative bg-white min-w-sm max-w-2xl flex flex-col md:flex-row items-center justify-center border py-2 px-2 focus-within:border-gray-300 mb-4"> -->
        <div
            class="relative bg-white min-w-sm flex flex-col md:flex-row items-center justify-center border py-2 px-2 focus-within:border-gray-300 mb-4">

            <input v-model="search" placeholder="your keyword here" name="q"
                class="px-4 py-2 w-full rounded-md flex-1 outline-none bg-white" required="">
            <button type="submit"
                class="w-full md:w-auto px-4 py-3 secondary border-black text-white fill-white active:scale-95 duration-100 border will-change-transform overflow-hidden relative transition-all">
                <div class="flex items-center transition-all opacity-1">
                    <v-icon size="20" class="mr-2 primaryAccent--text">{{
                        icons.mdiMagnify
                        }}</v-icon>
                    <span class="text-sm font-semibold whitespace-nowrap truncate mx-auto">
                        Search
                    </span>
                </div>
            </button>
        </div>
        <v-data-table :headers="headers" :items="isProduct === true ? produits : services" :loading="isDataIsLoading"
            loading-text="Loading... Please wait" :search="search" sort-by="title, domain"
            class="elevation-1 row-pointer" style="cursor: pointer">
            <!-- style="cursor: pointer" @click:row="(item) => viewDataTableElement(item)"> -->
            <template v-slot:item.name="{ item }">
                <v-col class="d-flex flex-row">
                    <span>{{ item.name }}</span>
                </v-col>
            </template>
            <template v-slot:item.title="{ item }">
                <v-col class="d-flex flex-row">
                    <span>{{ item.title }} </span>
                </v-col>
            </template>

            <template v-slot:item.domain="{ item }">
                <v-col class="d-flex flex-row">
                    <span>{{ item.domain }}</span>
                </v-col>
            </template>
            <template v-slot:item.status="{ item }">
                <div class="d-flex flex-row align-center primary--text" v-if="parseInt(item.status) === 1">
                    <v-icon size="13" class="mr-1 primary--text">{{
                        icons.mdiCheckboxMarkedCircle
                        }}</v-icon>
                    <span class="text-caption primary--text">Published</span>
                </div>
                <div class="d-flex flex-row align-center primary--text" v-if="parseInt(item.status) === 0">
                    <v-icon size="13" class="mr-1 secondary--text">{{
                        icons.mdiAlertCircle
                        }}</v-icon>
                    <span class="text-caption secondary--text">Draft</span>
                </div>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-col class="d-flex flex-row justify-space-between">
                    <v-icon size="32" color="primary" class="mr-2" small @click.stop="viewDataTableElement(item)">
                        {{ icons.mdiEye }}
                    </v-icon>
                    <v-icon size="32" color="secondary" class="mr-2" small @click.stop="editDaTableElement(item)"
                        v-if="parseInt(item.status) === 0">
                        {{ icons.mdiSquareEditOutline }}
                    </v-icon>
                    <v-icon size="32" class="mr-2" small v-else>
                        {{ icons.mdiSquareEditOutline }}
                    </v-icon>

                    <v-icon size="32" color="error" small @click.stop="confirmDelete(item)">
                        {{ icons.mdiDelete }}
                    </v-icon>
                </v-col>
            </template>
        </v-data-table>
        <v-dialog v-model="deleteDialog" max-width="400px">
            <div class="dialog-box">
                <div>
                    <v-icon size="64" class="mr-2 error--text" @click="closeDialog()">{{
                        icons.mdiAlertCircle
                        }}</v-icon>
                </div>
                <div class="dialog-main">
                    <div class="dialog-head">
                        <h1 v-if="isProduct">Delete product?</h1>
                        <h1 v-else>Delete service?</h1>

                        <div class="dialog-close-icon">
                            <v-btn icon small color="error" class="border suberror" @click="closeDialog()">
                                <v-icon size="24" class="ma-2" color="error">{{
                                    icons.mdiCloseCircle
                                    }}</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <div class="dialog-content">
                        <span>{{ deleteItem.title }}</span>
                    </div>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text small color="secondary" class="border subsecondary"
                            @click="closeDialog()">Cansel</v-btn>
                        <v-btn :loading="loader.delete" text small color="error" class="suberror"
                            @click="deleteProductService()">Delete</v-btn>
                    </v-card-actions>
                </div>
            </div>
        </v-dialog>
        <!-- <dialog-card @show="dialog">dia</dialog-card> -->
    </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiEye, mdiShareAll, mdiAlertCircle, mdiCloseCircle, mdiCheckboxMarkedCircle, mdiSquareEditOutline, mdiDelete } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import DialogCard from '@/views/dashboard/components/metas/DialogCard.vue'

var md5 = require("md5")

export default {
    components: {
        DialogCard
    },
    props: {
        isProduct: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            servicelistdata: [],
            services: [],
            produits: [],
            isDataIsLoading: true,
            editedIndex: -1,
            deleteIndex: -1,
            deleteDialog: false,
            dialog: false,
            deleteItem: {},
            loader: {
                delete: false
            }
        }
    },

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
        },
    },

    beforeMount() {
        this.isDataIsLoading = true
        Drequest.api(`lazyloading.service?dfilters=on&enterprise_id:eq=${this.$store.getters.getCompany.id}`)
            .get((response) => {
                switch (response.success) {
                    case true:
                        response.listEntity.forEach((value, index) => {
                            this.servicelistdata.push({
                                id: value.id,
                                name: value.name,
                                title: value.title,
                                domain: value.domain,
                                enterprise: value.enterprise,
                                status: value.status,
                                pos: index + 1
                            })
                        })
                        this.isDataIsLoading = false
                        const [a, b] = this.servicelistdata.reduce((acc, element) => {
                            if (element.name === 'Service') {
                                this.services.push(element); // Ajoute aux correspondants
                            } else {
                                this.produits.push(element); // Ajoute aux non correspondants
                            }
                            return acc;
                        }, [[], []]);
                        break;
                    default:
                        this.$fire({
                            title: "Erreur inattendue!",
                            type: "error",
                            text: "Veuillez recharger la page..!",
                            timer: 3000
                        })
                        this.isDataIsLoading = false
                        break;
                }
            })
            .catch((err) => {
                console.log("err")
            })
    },

    methods: {
        dialogStatus(isVisible){
            this.dialog = !this.dialog
        },
        highlightClickedRow(value) {
            const tr = value.target.parentNode;
            tr.classList.add('primary');
        },

        closeDialog() {
            this.deleteDialog = !this.deleteDialog
            this.deleteItem = {}
        },
        confirmDelete(item) {
            this.deleteItem = item
            this.deleteDialog = !this.deleteDialog
        },
        viewDataTableElement(item) {
            this.$router.push({ name: 'company.service.view', params: { company: this.$store.getters.getCompany.name, f_id: item.id, title: item.title } })
        },
        editDaTableElement(item){
      this.$router.push({ name: 'company.service.edit', params: { company: this.$store.getters.getCompany.name, value: item.id, title: item.title } })
        },
        deleteProductService() {
            this.loader.delete = true

            if (this.isProduct === true) {
                this.services = this.products.filter(item => item.id !== this.deleteItem.id)
            }
            else {
                this.services = this.services.filter(item => item.id !== this.deleteItem.id)
            }

            // Drequest.api(`service.popper?dfilters=on&service_id:eq=${this.deleteItem.id}`)
            //     .get((response) => {
            //         if (response.success === true) {
            //             if (this.isProduct === true) {
            //                 this.services = this.products.filter(item => item.id !== this.deleteItem.id)
            //             }
            //             else {
            //                 this.services = this.services.filter(item => item.id !== this.deleteItem.id)
            //             }
            //             this.loader.delete = false
            //         }
            //         else {
            //             this.loader.delete = false
            //         }
            //     })
            //     .catch((err) => {
            //         this.loader.delete = false
            //         console.log("err")
            //     })
            this.deleteDialog = !this.deleteDialog
            this.loader.delete = false

        },

        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.servicelistdata[this.editedIndex], this.editedItem)
            } else {
                this.servicelistdata.push(this.editedItem)
            }
            this.closeDialog()
        },
    },
    setup(props) {
        const icons = { mdiMagnify, mdiEye, mdiShareAll, mdiAlertCircle, mdiCloseCircle, mdiCheckboxMarkedCircle, mdiSquareEditOutline, mdiDelete }
        let search = ref('')

        const headers = [
            { text: 'Name', value: 'name' },
            {
                text: 'Title',
                align: 'start',
                sortable: true,
                value: 'title',
            },
            { text: 'Domain', value: 'domain' },
            { text: 'Status', value: 'status' },
            { text: 'Actions', value: 'actions', sortable: false },
        ]
        return {
            headers, search, icons
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/styles/form-style.scss";

.row-pointer>>>tbody tr :hover {
    cursor: pointer;
}

.dialog-box {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: var(--v-base-primaryAccent);
    background: #FFF;
    padding: 0.4em 0.2em;

    .dialog-close {
        position: absolute;
        top: 0;
        right: 0;
    }

    .dialog-main {
        width: 100%;
    }

    .dialog-head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.4em 0.2em;
    }

    .dialog-head h1 {
        font-weight: bold;
    }
}

.search-field {}
</style>