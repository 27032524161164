<template>
    <v-dialog v-model="dialog" max-width="400px">
        <div class="dialog-box">
            <div>
                <v-icon size="64" class="mr-2 error--text" @click="dialog()">{{
                    icons.mdiAlertCircle
                    }}</v-icon>
            </div>
            <div class="dialog-main">
                <div class="dialog-head">
                    <h1>Delete service?</h1>

                    <div class="dialog-close-icon">
                        <v-btn icon small color="error" class="border suberror" @click="close()">
                            <v-icon size="24" class="ma-2" color="error">{{
                                icons.mdiCloseCircle
                                }}</v-icon>
                        </v-btn>
                    </div>
                </div>
                <div class="dialog-content">
                    <span>deleteItem.title</span>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text small color="secondary" class="border subsecondary"
                        @click="close()">Cansel</v-btn>
                    <v-btn :loading="loading.delete" text small color="error" class="suberror"
                        @click="close()">Delete</v-btn>
                </v-card-actions>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiEye, mdiShareAll, mdiAlertCircle, mdiCloseCircle, mdiCheckboxMarkedCircle, mdiSquareEditOutline, mdiDelete } from '@mdi/js'

var md5 = require("md5")

export default {
    components: {

    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            dialog: true,
            loading: {
                delete: false
            }
        }
    },

    computed: {

    },

    beforeMount() {
    },

    methods: {
        close() {
            this.dialog = false
        }
    },
    setup(props) {
        const icons = { mdiMagnify, mdiEye, mdiShareAll, mdiAlertCircle, mdiCloseCircle, mdiCheckboxMarkedCircle, mdiSquareEditOutline, mdiDelete }
        let search = ref('')
        return {
            icons
        }
    },
}
</script>

<style lang="scss" scoped>
.dialog-box {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: var(--v-base-primaryAccent);
    background: #FFF;
    padding: 0.4em 0.2em;

    .dialog-close {
        position: absolute;
        top: 0;
        right: 0;
    }

    .dialog-main {
        width: 100%;
    }

    .dialog-head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.4em 0.2em;
    }

    .dialog-head h1 {
        font-weight: bold;
    }
}

.search-field {}
</style>