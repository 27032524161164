import { render, staticRenderFns } from "./ServiceDataTable.vue?vue&type=template&id=2a85e3ce&scoped=true&"
import script from "./ServiceDataTable.vue?vue&type=script&lang=js&"
export * from "./ServiceDataTable.vue?vue&type=script&lang=js&"
import style0 from "./ServiceDataTable.vue?vue&type=style&index=0&id=2a85e3ce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a85e3ce",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCardActions,VCol,VDataTable,VDialog,VIcon,VSpacer})
